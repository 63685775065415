<template>
    <div class="quot-page">
        <van-nav-bar :border="false" :title="$t('收益记录')">
        </van-nav-bar>
        <div class="quo-wrapper">
            <div class="robot-data-card" v-if="user.stat">
                <router-link class="share-link" :to="'/share?key=' + key + '&uname=' + user.username">
                    <span class="iconfont icon-tubiaozhizuo-"></span>
                    <span>{{ $t('分享') }}</span>
                    <!-- <vue-goodshare-facebook
                    :page_url="this.share_url"
                    :title_social="$t('分享')"
                    button_design="none"
                    has_icon
                    ></vue-goodshare-facebook> -->
                </router-link>
                <div class="uc-col">
                    <div class="uc-label" >{{$t('日收益')}}</div>
                    <div class="uc-val">{{parseFloat(user.stat.today_income).toFixed(6)}} {{ $t('USDT') }}</div>
                </div>
                <div class="uc-col">
                    <div class="uc-label" >{{$t('总收益')}}</div>
                    <div class="uc-val">{{parseFloat(user.stat.today_incomes).toFixed(6)}} {{ $t('USDT') }}</div>
                </div>
            </div>
            <div class="bs-panel">
                <!-- <van-list
                        v-model="loading"
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="onLoad"
                > -->
                    <div class="rec-cell flex-center" v-for="(item, index) in initList" :key="index">
                        <!-- <img :src="item.logo" class="rec-icon" v-if="item.logo"> -->
                        <img :src="item.currency.logo" class="rec-icon">
                        <div class="flex_bd" v-if="item.currency">
                            <div class="rec-tit">{{item.currency.name}}</div>
                            <div class="rec-time text-gray">{{item.updated_at}}</div>
                        </div>
                        <div class="rec-icon">
                        <div style="font-size: 20px"><i class="iconfont"
                        :class="item.profit >= 0 ? 'icon-up':'icon-dn'"></i></div>
                        </div>
                        <div class="rec-right" style="min-width:65px">
                            <div class="rec-per" :class="item.profit >= 0 ? 'text-green':'text-red'">
                                {{ item.profit >= 0 ? '+':'' }}{{parseFloat(item.profit).toFixed(6)}} {{ $t('USDT') }}
                            </div>
                            <div class="text text-gray">{{$t('收益')}}</div>
                        </div>
                    </div>
                    <div class="load-foot">
                        <div v-if="finished" class="text-gray">{{$t('没有更多了')}}</div>
                        <van-button v-else :loading="loading" type="primary" block @click="loadMore">{{$t('加载更多')}}</van-button>
                    </div>
            </div>
            <div class="bs-panel rev-help" >
                <div class="rev-top">{{$t('如何开启我的第一笔收益')}}</div>
                <div class="rev-help-list">
                    <!-- <div class="rev-row">
                        <div class="rev-title">{{$t('1.领取第一个免费机器人')}}</div>
                        <router-link to="/" class="rev-link">{{$t('去领取')}}</router-link>
                    </div> -->
                    <div class="rev-row">
                        <div class="rev-title">{{$t('购买交易机器人')}}</div>
                        <router-link to="/" class="rev-link">{{$t('去购买')}}</router-link>
                    </div>
                    <!-- <div class="rev-row">
                        <div class="rev-title">{{$t('3.邀请好友获得收益')}}</div>
                        <router-link :to="'/share?key=' + key + '&uname=' + user.username" class="rev-link">{{$t('去邀请')}}</router-link>
                    </div> -->
                </div>
            </div>
        </div>
        <Foot :active="1"/>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";
    import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
    export default {
        components: {Foot},
        data() {
            return {
                b_timer: '',
                b_load: false,
                show: false,
                name: '',
                list: [],
                hq_loading: true,
                loading: false,
                finished: false,
                page: 0,
                size: 20,
                robot_info: null,
                exchange: [],
                result: [],
                hq_info: [],
                user: {},
                share_url:'',
                key:''
            }
        },
        computed:{
            initList(){
                const logoList = Object.entries(this.hq_info)
                if(!logoList.length){
                    return this.list
                }
                return this.list.map(item=>{
                    const num = parseInt(item.updated_at.slice(-2)) %  logoList.length
                    return {
                        ...item,
                        logo:logoList[num][1].logo
                    }
                })
            }
        },
        methods: {
            getKey() {
                this.$axios
                    .get('/user/codes')
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.key = res.data.data;
                        }
                    });
            },
            toggle(url) {
                if (url) {
                    window.open(url, '_blank');
                }
            },

            url(url) {
                this.$router.push({
                    path: url
                })
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            getUser() {
                // setTimeout(()=>{
                this.$axios
                    .get(this.$api_url.user + "?type=2")
                    .then((res) => {
                        this.user = res.data;
                    });
                // }, 20002);
            },
            getList() {
                this.loading = true; // 开始加载，将loading状态设置为true
                this.$axios
                    .get(this.$api_url.order_list + '?page=' + this.page)
                    .then((res) => {
                        this.page = this.page + 1;
                        this.list = this.list.concat(res.data.data);
                        if (res.data.data.length < this.size) {
                            this.finished = true;
                        }
                        this.loading = false;
                    });
            },
            getUrl(){
                this.$axios.get(this.$api_url.v_login).then((res) => {
                    if (res.data.code == 0) {
                        this.share_url = res.data.data.share_url;
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            loadMore() {
                this.getList(); // 调用getList方法加载更多数据
            },
        },
        mounted() {
            this.getUser()
            this.getList()
            this.getUrl()
            this.getKey()
        },
        destroyed() {
            clearInterval(this.b_timer);
        },
    }
</script>
<style scoped>
    .quot-page ::v-deep .whs-service{
        display: none;
    }
    .rev-help{
        padding: 16px;
        background: url(../../assets/pictures/card-no-bg.jpg) center no-repeat;
        background-size: cover;
        font-size: 14px;
    }
    .rev-top{
        font-size: 14px;
        text-align: center;
        margin-bottom: 15px;
    }
    .rev-row{
        display: flex;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .rev-title{
        flex: 1;
    }
    .rev-link{
        color: #3286F2;
    }

    .quot-page{
        background: url(../../assets/pictures/level-bg.jpg) center -70px no-repeat;
        background-size: contain;
    }
    .robot-data-card{
        position: relative;
        background: url(../../assets/pictures/cardbg.jpg) no-repeat;
        background-size: cover;
        border-radius: 16px;
        color: #fff;
        text-align: center;
        font-size: 15px;
        display: flex;
        flex-wrap: wrap;
        line-height: 1.2;
        padding: 50px 0 30px 0;
        margin-bottom: 20px;
    }
    .share-link{
        display: flex;
        align-items: center;
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff;
    }

    .robot-data-card .uc-col{
        position: relative;
        width: 50%;
    }
    .uc-label{
        margin-bottom: 10px;
    }
    .robot-data-card .uc-col::after{
        content: '';
        width: 1px;
        height: 40px;
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .robot-data-card .uc-col:last-child::after{
        display: none;
    }
    .robot-data-card .uc-col-full{
        width: 100%;
    }
    .robot-data-card .uc-val{
        font-size: 20px;
        margin-top: 8px;
    }
    .load-foot{
        padding: 15px;
        text-align: center;
        font-size: 14px;
    }

    .share-link .iconfont{
        margin-right: 5px;
    }

    
</style>




